import DatabaseService from "src/lib/database";
const FILE_MANAGER_MAP = new Map();
export default class FileManager {
    get filenames() {
        return this._filenamesList ?? (this._filenamesList = [...this._filenames].sort((a, b) => {
            const lowerA = a.toLowerCase();
            const lowerB = b.toLowerCase();
            if (lowerA < lowerB)
                return -1;
            if (lowerA > lowerB)
                return 1;
            return 0;
        }));
    }
    constructor(_dbStore, _filenames) {
        this._dbStore = _dbStore;
        this._filenames = _filenames;
    }
    static async initialize(dbStore) {
        return new Promise(async (resolve) => {
            if (FILE_MANAGER_MAP.has(dbStore))
                return resolve(FILE_MANAGER_MAP.get(dbStore));
            const filenames = await DatabaseService.getAllKeys(dbStore);
            const fm = new FileManager(dbStore, new Set(filenames));
            FILE_MANAGER_MAP.set(dbStore, fm);
            resolve(fm);
        });
    }
    static getInstance(dbStore) {
        const fm = FILE_MANAGER_MAP.get(dbStore);
        if (!fm)
            throw new Error(`Tried to get '${dbStore}' file manager before it was initialized.`);
        return fm;
    }
    async getFileContent(filename) {
        return new Promise(async (resolve, reject) => {
            if (!this._filenames.has(filename))
                return reject(`Filename '${filename}' does not exist.`);
            DatabaseService
                .getItem(this._dbStore, filename)
                .then(content => resolve(content))
                .catch(err => reject(err));
        });
    }
    getFirstFilename() {
        return this._filenames.size === 0 ? undefined : this.filenames[0];
    }
    hasFile(filename) {
        return this._filenames.has(filename);
    }
    async setFileContent(filename, content) {
        return new Promise(async (resolve, reject) => {
            if (!this._filenames.has(filename))
                return reject(`Filename '${filename}' does not exist.`);
            await DatabaseService.setItem(this._dbStore, filename, content);
            resolve();
        });
    }
    async tryAdd(filename, content, safe = false) {
        return new Promise(async (resolve, reject) => {
            if (this._filenames.has(filename)) {
                if (safe)
                    return resolve();
                return reject(`Filename '${filename}' already exists.`);
            }
            await DatabaseService.setItem(this._dbStore, filename, content);
            this._filenames.add(filename);
            this._filenamesList = undefined;
            resolve();
        });
    }
    async tryDelete(...filenames) {
        return new Promise(async (resolve, reject) => {
            for (let i = 0; i < filenames.length; ++i) {
                const filename = filenames[i];
                if (!this._filenames.has(filename))
                    return reject(`Filename '${filename}' does not exist.`);
                await DatabaseService.removeItem(this._dbStore, filename);
                this._filenames.delete(filename);
            }
            this._filenamesList = undefined;
            resolve();
        });
    }
    async tryRename(oldName, newName) {
        return new Promise(async (resolve, reject) => {
            if (oldName === newName)
                return resolve();
            if (!this._filenames.has(oldName))
                return reject(`Filename '${oldName}' does not exist.`);
            if (this._filenames.has(newName))
                return reject(`Filename '${newName}' already exists.`);
            const content = await DatabaseService.getItem(this._dbStore, oldName);
            await DatabaseService.removeItem(this._dbStore, oldName);
            this._filenames.delete(oldName);
            await DatabaseService.setItem(this._dbStore, newName, content);
            this._filenames.add(newName);
            this._filenamesList = undefined;
            resolve();
        });
    }
}
