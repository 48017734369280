// https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API/Using_IndexedDB
let db;
let hasInitialized = false;
const DB_NAME = "S4TKSandboxDB";
const DB_VERSION = 1;
const STORE_API = "api";
const STORE_API_SPECS = "api_specs";
const STORE_MEDIA = "media";
const STORE_SCRIPT = "script";
//#region Helpers
const getStore = (store, mode) => db
    .transaction(store, mode)
    .objectStore(store);
async function handleDbError(event) {
    console.error(`Database error: ${event.target.errorCode}`);
}
async function handleDbUpgrade(event) {
    // when version increments, make sure the below code doesn't run again
    db.createObjectStore(STORE_API);
    db.createObjectStore(STORE_API_SPECS);
    db.createObjectStore(STORE_MEDIA);
    db.createObjectStore(STORE_SCRIPT);
}
async function initializeDatabase() {
    return new Promise((resolve, reject) => {
        if (hasInitialized)
            return resolve();
        const request = indexedDB.open(DB_NAME, DB_VERSION);
        const loadDb = async (event) => {
            db = event.target.result;
            db.onerror = handleDbError;
            hasInitialized = true;
        };
        request.onerror = () => {
            reject("Could not initialize indexed database.");
            hasInitialized = true;
        };
        request.onsuccess = (event) => {
            loadDb(event).then(() => {
                resolve();
            });
        };
        request.onupgradeneeded = (event) => {
            loadDb(event).then(() => {
                handleDbUpgrade(event);
            });
        };
    });
}
//#endregion Helpers
/**
 * An abstraction over indexedDB.
 */
var DatabaseService;
(function (DatabaseService) {
    async function clear(store) {
        initializeDatabase().then(() => {
            getStore(store, "readwrite").clear();
        });
    }
    DatabaseService.clear = clear;
    async function getAll(store) {
        return new Promise((resolve, reject) => {
            initializeDatabase().then(() => {
                const request = getStore(store, "readonly").getAll();
                request.onerror = (event) => {
                    reject(`Could not get item from DB: ${event.target.errorCode}`);
                };
                request.onsuccess = () => {
                    resolve(request.result);
                };
            });
        });
    }
    DatabaseService.getAll = getAll;
    async function getAllKeys(store) {
        return new Promise((resolve, reject) => {
            initializeDatabase().then(() => {
                const request = getStore(store, "readonly").getAllKeys();
                request.onerror = (event) => {
                    reject(`Could not get item from DB: ${event.target.errorCode}`);
                };
                request.onsuccess = () => {
                    resolve(request.result.map(k => k.toString()));
                };
            });
        });
    }
    DatabaseService.getAllKeys = getAllKeys;
    async function getItem(store, key) {
        return new Promise((resolve, reject) => {
            initializeDatabase().then(() => {
                const request = getStore(store, "readonly").get(key);
                request.onerror = (event) => {
                    reject(`Could not get item from DB: ${event.target.errorCode}`);
                };
                request.onsuccess = () => {
                    resolve(request.result);
                };
            });
        });
    }
    DatabaseService.getItem = getItem;
    async function removeItem(store, key) {
        initializeDatabase().then(() => {
            getStore(store, "readwrite").delete(key);
        });
    }
    DatabaseService.removeItem = removeItem;
    async function setItem(store, key, value) {
        initializeDatabase().then(() => {
            getStore(store, "readwrite").put(value, key);
        });
    }
    DatabaseService.setItem = setItem;
})(DatabaseService || (DatabaseService = {}));
export default DatabaseService;
